<template>
    <div>
        <div v-if="isLoading" style="min-height: 300px"
             class="d-flex align-items-center justify-content-center flex-column">
            <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
            <h6 class="text-body mt-3">Loading</h6>

        </div>
        <div v-if="!isLoading && errorLoading" style="min-height: 300px"
             class="d-flex align-items-center justify-content-center flex-column">
            <b-icon-exclamation-triangle class="h3" variant="danger"></b-icon-exclamation-triangle>
            <h6 class="text-danger mt-3">Ooops, there has been an error</h6>

        </div>
        <div v-if="!isLoading && !errorLoading">
            <h5 class="border-bottom pb-2 text-primary">{{ formData.id !== undefined ? "Edit" : "Create"}} document
                type</h5>
            <form autocomplete="off">
                <b-form-group
                        label-size="sm"
                        label="Name:">
                    <b-form-input size="sm" v-model="formData.name" placeholder="Document name"></b-form-input>
                    <error-display v-model="errors" ident="name"></error-display>
                </b-form-group>

                <b-form-group
                        label-size="sm"
                        label="Only show for these nationality groups:">
                    <api-select-input
                            :original-options="nGroups"
                            v-model="formData.nationality_group_id"
                            :multiple="false"
                            api-endpoint-param="keywords"
                            :api-endpoint="apiEndpoint"
                            placeholder="Search for nationality group">

                    </api-select-input>
                    <error-display v-model="errors" ident="nationality_group_id"></error-display>
                </b-form-group>

                <b-form-group
                        label-size="sm"
                        label="Show only for the following student status:">
                    <b-select size="sm" v-model="formData.list_type" :options="listTypes"></b-select>
                    <error-display v-model="errors" ident="list_type"></error-display>
                </b-form-group>

                <hr aria-orientation="horizontal" class="dropdown-divider mt-3 mb-3">
                <h6 class="mb-2 d-flex">
                    <span class="flex-fill">Captures for this document type</span>
                    <div class="flex-wrap">
                        <b-button @click="addCapture" size="sm" variant="outline-secondary">
                            <b-icon-plus></b-icon-plus>
                            Add capture
                        </b-button>
                    </div>
                </h6>
                <div v-if="formData.captures === undefined || formData.captures.length < 1">
                    <b-alert variant="info" show>
                        No captures for this document type yet.
                    </b-alert>
                </div>
                <error-display v-model="errors" ident="captures"></error-display>
                <div class="row row-deck">
                    <div v-for="(capture,captureKey) in formData.captures" class="col-6 d-flex flex-column" v-bind:key="captureKey">
                        <div class="m-1 border p-3 flex-fill">
                            <b-form-group
                                    label-size="sm"
                                    label="Name:">
                                <b-form-input size="sm" v-model="capture.name"
                                              placeholder="Document name"></b-form-input>
                                <error-display v-model="errors" :ident="'captures.'+captureKey+'.name'"></error-display>
                            </b-form-group>

                            <b-form-group
                                    label-size="sm"
                                    label="Instructions:">
                                <b-form-textarea size="sm" v-model="capture.instructions"
                                                 placeholder="Instructions"></b-form-textarea>
                                <error-display v-model="errors"
                                               :ident="'captures.'+captureKey+'.instructions'"></error-display>
                            </b-form-group>

                            <b-form-group
                                    label-size="sm"
                                    label="Name:">
                                <b-form-input size="sm" v-model="capture.relation_code"
                                              placeholder="Relation code"></b-form-input>
                                <error-display v-model="errors"
                                               :ident="'captures.'+captureKey+'.relation_code'"></error-display>
                            </b-form-group>

                            <b-form-group
                                    label-size="sm"
                                    label="File type:">
                                <b-select size="sm" v-model="capture.file_type" :options="docTypes"></b-select>
                                <error-display v-model="errors"
                                               :ident="'captures.'+captureKey+'.file_type'"></error-display>
                            </b-form-group>

                            <b-form-group
                                    label-size="sm"
                                    label="Proportion:">
                                <b-form-input size="sm" v-model="capture.proportion"
                                              placeholder="Proportion"></b-form-input>
                                <error-display v-model="errors"
                                               :ident="'captures.'+captureKey+'.proportion'"></error-display>
                            </b-form-group>

                            <b-form-group>
                                <b-form-checkbox
                                        size="sm"
                                        v-model="capture.expires"
                                        :value="1"
                                        unchecked-value="0"
                                >Require expiry date
                                </b-form-checkbox>
                            </b-form-group>

                            <b-form-group>
                                <b-form-checkbox
                                        size="sm"
                                        v-model="capture.machine_readable"
                                        :value="1"
                                        unchecked-value="0"
                                >Machine readable (MRZ)
                                </b-form-checkbox>
                            </b-form-group>

                            <b-form-group>
                                <b-form-checkbox
                                        size="sm"
                                        v-model="capture.nfc_readable"
                                        :value="1"
                                        unchecked-value="0"
                                >NFC readable
                                </b-form-checkbox>
                            </b-form-group>

                            <b-form-group class="mb-0 mt-4 border-top pt-3">
                                <b-button @click="() => { deleteCapture(capture.id) }" size="sm"
                                          variant="outline-danger">
                                    <b-icon-trash></b-icon-trash>
                                    Delete
                                </b-button>
                            </b-form-group>

                        </div>
                    </div>
                </div>

                <hr aria-orientation="horizontal" class="dropdown-divider mt-3 mb-3">
                <h6 class="mb-2 d-flex">
                    <span class="flex-fill">Questions for this document type</span>
                    <div class="flex-wrap">
                        <b-button @click="addQuestion" size="sm" variant="outline-secondary">
                            <b-icon-plus></b-icon-plus>
                            Add question
                        </b-button>
                    </div>
                </h6>
                <error-display v-model="errors" ident="questions"></error-display>
                <div v-if="formData.questions === undefined || formData.questions.length < 1">
                    <b-alert variant="info" show>
                        No questions for this document type.
                    </b-alert>
                </div>


                <div v-for="(question,questionKey) in formData.questions" v-bind:key="questionKey">
                    <b-form-group
                            label-size="sm"
                            :label="'Question '+(questionKey+1)+':'">
                        <div class="d-flex">
                            <div class="flex-fill">
                            <b-form-input size="sm" v-model="question.question"
                                          placeholder="Enter Yes/No question here"></b-form-input>
                            <error-display v-model="errors" :ident="'questions.'+questionKey+'.question'"></error-display>
                            </div>
                            <div class="flex-wrap ml-1">
                                <b-button @click="() => { deleteQuestion(question.id) }" size="sm"
                                          variant="outline-danger">
                                    <b-icon-trash></b-icon-trash>
                                </b-button>
                            </div>
                        </div>
                    </b-form-group>
                </div>

                <hr aria-orientation="horizontal" class="dropdown-divider mt-3 mb-3">

                <div class="text-right mt-3">
                    <b-button @click="saveModel" variant="success">Save</b-button>
                </div>
            </form>

        </div>
    </div>
</template>

<script>
    import {getResource, saveResource} from "../../modules/api/methods";
    import ErrorDisplay from "../form_inputs/ErrorDisplay";
    import _ from 'lodash'
    import uuid from 'uuid';
    import {additionalDocumentSave, baseApi, documentTypeDetail, documentTypeSave} from "../../modules/api/endpoints";
    import ApiSelectInput from "../form_inputs/ApiSelectInput";
    import {containsErrors} from "../../modules/helpers/helpers";

    export default {
        name: "DocumentTypeEdit",
        components: {ApiSelectInput, ErrorDisplay},
        props: {
            id: null,
        },
        data: function () {
            return {
                errorLoading : false,
                listTypes: [
                    {value: 'A', text: 'List A'},
                    {value: 'B1', text: 'List B1'},
                    {value: 'B2', text: 'List B2'},
                ],
                docTypes: [
                    {value: 'Passport', text: 'Passport'},
                    {value: 'Visa', text: 'Visa'},
                    {value: 'NationalIDCard', text: 'National ID Card'},
                ],
                nGroups: [],
                isLoading: false,
                formData: {
                    captures: [],
                    questions: [],
                },
                errors: {},
                apiEndpoint: baseApi() + 'nationality-groups/dropdown'
            }
        },
        created() {
            if (this.id != null) {
                this.loadModel();
            }
        },
        methods: {
            addCapture() {
                this.formData.captures.push({id: uuid()})
            },
            deleteCapture(captureId) {
                this.formData.captures = _.remove(this.formData.captures, function (obj) {
                    return obj.id !== captureId
                })
            },
            addQuestion() {
                this.formData.questions.push({id: uuid()})
            },
            deleteQuestion(questionId) {
                this.formData.questions = _.remove(this.formData.questions, function (obj) {
                    return obj.id !== questionId
                })
            },
            loadModel() {
                this.isLoading = true;
                getResource(documentTypeDetail(this.id)).then((resp) => {
                    console.log("DOC TYPE", resp.data.success.data);
                    var data = resp.data.success.data;
                    if (data.nationality_group !== undefined) {
                        this.nGroups = [{value: data.nationality_group.id, name: data.nationality_group.name}]
                    }
                    //TRANSFORM DATA
                    this.formData = data;
                    this.isLoading = false;
                }).catch((ex) => {
                    this.isLoading = false;

                });
            },
            saveModel() {
                this.isLoading = true;
                this.errors = {};
                saveResource(documentTypeSave, this.formData).then((resp) => {

                    this.isLoading = false;
                    this.$router.push({'name': 'documentTypes', params: {data_updated: true}}).catch(error => {});
                }).catch((err) => {
                    console.log(err);
                    if (containsErrors(err)) {
                        this.errors = err.data.errors;
                    }
                    this.isLoading = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>
